import React from "react";
import { navigate } from "gatsby";
import { GatsbyImage, getImage, ImageDataLike, GatsbyImageProps } from "gatsby-plugin-image";
import classNames from "classnames";
import {useHover, BasicLink, FancyLink} from "gatsby-theme-jqi";
import "./CourseFeedItem.scss";


const CourseFeedItem: React.FC<JQI.CourseFeedItemProps> = ({
  title,
  fields,
  body,
  relationships,
  hideDescription
}) => {
  const [ hoverRef, hovered ] = useHover();
  const classList = classNames({
    "article-feed-item": true,
    "article-feed-item--large": !hideDescription,
    "article-feed-item--small": hideDescription,
    hovered,
  });
  const imageData = relationships?.hero?.relationships.heroImage?.relationships.mediaImage.localFile as ImageDataLike;
  const imageProps = {
    image: !!imageData ? getImage(imageData) : null,
    alt: relationships?.hero?.relationships.heroImage?.mediaImage.alt || "",
  } as GatsbyImageProps;

  return (
    <article className={classList} onClick={() => { navigate(fields.pagePath) }} ref={hoverRef}>
      <div className="article-feed-item__content">
        <h2 className="article-feed-item__title">
          {
            !!fields?.pagePath
              ? <BasicLink url={fields.pagePath}>{title}</BasicLink>
              : <span>{title}</span>
          }
        </h2>
        {
          !!relationships?.semesters?.length && <div className="article-feed-item__semester">{relationships.semesters?.map((item, index: number) => <h3 className="semester__title" key={"semester__title_" + index}>{item.name}</h3>)}</div>
        }
        {
          !!relationships?.people?.length && <div className="article-feed-item__people">{relationships.people?.map((item, index) => <span key={"article-feed-item__people" + index}>{item.name},{item.relationships.roles?.map((rol) => rol.name).join(", ")}</span>)}</div>
        }
        {
          (!!body?.summary && !hideDescription) &&
          <p className="article-feed-item__teaser" dangerouslySetInnerHTML={{ __html: body.summary }}></p>
        }
        {
          !!fields?.pagePath && <FancyLink href={fields.pagePath}>Learn More</FancyLink>
        }
      </div>
    </article>
  );
};

export default CourseFeedItem;
