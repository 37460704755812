import React, { useState } from "react";
import classNames from "classnames";

const ArticleFeed: React.FC<JQI.ArticleFeedProps> = ({
  children,
  className,
  limit,
  showMore,
}) => {
  const classList = classNames({
    "article-feed": true,
    "flex": true,
    "flex-col": true,
    "justify-center": true,
    [`${className}`]: !!className,
  });
  const childCount = React.Children.count(children);
  const [count, setCount] = useState(limit || childCount);
  const childrenToShow = React.Children.toArray(children).slice(0, count);
  return (
    <div className={classList}>
      <ul className="article-feed__list mb-4">
        {
          childrenToShow.map((child, i) => (
            <li className="article-feed__list-item" key={`article-feed-item-${i}`}>
              {child}
            </li>
          ))
        }
      </ul>
      {
        (!!showMore && childCount > count) &&
        <div>
          <button type="button" className="button button--gray article-feed__show-more" onClick={() => setCount(childCount)}><span className="button__label">Load More News<span className="button__icon" aria-hidden="true"></span></span></button>
        </div>
      }
    </div>
  );
};

export default ArticleFeed;
