import "@fontsource/playfair-display/700.css";
import "@fontsource/playfair-display/900.css";
import "@fontsource/playfair-display/400.css";
import "@fontsource/nunito-sans/300.css";
import "@fontsource/nunito-sans/400.css";
import "@fontsource/nunito-sans/500.css";
import "@fontsource/nunito-sans/600.css";
import "@fontsource/nunito-sans/700.css";
import "@fontsource/nunito-sans/900.css";

import React from "react";
import { AccessibilityUtils } from "gatsby-theme-jqi";
import Provider from "./src/wrappers/Provider";
import "./src/styles/index.scss";

export const onClientEntry = () => {
  document.documentElement.classList.add("js");
  AccessibilityUtils.init();
  return true;
};

export const onInitialClientRender = () => {
  AccessibilityUtils.disableTabbingWithinHiddenElementsOnInitialRender();
}

export const wrapPageElement = ({ element, props }) => {
  return (
    <Provider {...props}>{element}</Provider>
  );
};