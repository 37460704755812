import React from "react";
import { PageProps, graphql } from "gatsby";
import _ from 'lodash';
import { useNavHierarchies } from "gatsby-jqi-graphql-helpers";
import { FancyLink, useSiteMetadata } from "gatsby-theme-jqi";
import { getImage, ImageDataLike } from "gatsby-plugin-image";
import { HTMLHead, ArticleFeed, ArticleFeedItem, EventFeed, EventFeedItem, GroupFeed, GroupFeedItem, PublicationFeed, PublicationFeedItem, PageContentLayout, TextHTMLParagraph, PersonHero, PageSection, PageContentMain, PageContentBody,getExternalGroups, getPublishedGroups, filterUnrelatedChildren, splitGroupsAndCenters, filterRelatedArticles, filterRelatedPublications } from "gatsby-theme-jqi";
import PeopleVisits from "../components/PeopleVisits";
import PostSidebarHeader from "../components/PostSidebarHeader";

const Person: React.FC<PageProps<JQI.PersonData, JQI.PersonContext>> = ({
  data,
  pageContext,
}) => {
  const { page, publications, otherVisits, events } = data;
  const { defaultNodesByPath = {}, groupId } = pageContext;
  defaultNodesByPath[page.fields.pagePath] = { title: page.name };
  const { breadcrumb } = useNavHierarchies(page.fields.pagePath, defaultNodesByPath)
  const { image, contactInfo, cv, researchAreas, groups, roles, articles, field_visit_date, affiliation, courses } = page.relationships;

  let externalGroups = groups ? getExternalGroups(groups, groupId) : [];
  externalGroups = filterUnrelatedChildren(externalGroups, groupId);
  externalGroups = getPublishedGroups(externalGroups);
  const { centers, researchGroups } = splitGroupsAndCenters(externalGroups);
  const localRelatedArticles = articles ? filterRelatedArticles(articles, groupId) : [];
  const localRelatedPublications = publications.nodes ? filterRelatedPublications(publications.nodes, groupId) : [];
  const personHeroProps = {
    name: page.name,
    roles: roles?.map((role) => role.name),
    personTitle: page.personTitle,
    image: {
      data: image?.relationships.mediaImage.localFile,
      alt: image?.mediaImage.alt,
    },
    contactInfo: contactInfo.map((info) => ({
      email: info.email,
      office: {
        address: info.officeAddress?.processed,
        phone: info.officePhone,
      },
      organization: info.relationships.organization?.name,
      lab: info.lab,
    })),
    cv: !!cv ? `${process.env.GATSBY_DRUPAL_URL}${cv?.relationships.mediaDocument.uri.url}` : "",
    researchProfile: page.researchProfile,
    breadcrumb,
    affiliations: affiliation?.map((affiliation) => affiliation.name),
  } as JQI.PersonHeroProps;

  const sortedArticles = _.orderBy(localRelatedArticles, "created", 'desc');
  const sortedPublications = _.orderBy(localRelatedPublications, ['year', 'title'], ['desc', 'asc']);

  const siteMetadata = useSiteMetadata();
  // Extract imageUrl for use in og:image
  const metaImageData = image?.relationships.mediaImage.localFile as ImageDataLike;
  const metaImage =  !!metaImageData ? getImage(metaImageData) : null;
  const imageUrl = siteMetadata.siteUrl + metaImage?.images.fallback?.src;

  // Other visitors
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const flattenedData = otherVisits.edges.flatMap(item => 
    item.node.relationships.field_visit_date?.map(visitDate => ({
      ...item,
      node: {
        ...item.node,
        relationships: {
          ...item.node.relationships,
          field_visit_date: visitDate
        }
      }
    }))
  );
  
  const sortedVisitData = flattenedData.sort((a, b) => {
    const dateA = new Date(a.node.relationships.field_visit_date.field_visit_start_date).getTime();
    const dateB = new Date(b.node.relationships.field_visit_date.field_visit_start_date).getTime();
    
    return dateA - dateB;
  });


  const filteredVisitData = sortedVisitData.filter(entry => {
      const entryDate = new Date(entry.node.relationships.field_visit_date.field_visit_start_date).getTime();
      return entryDate >= today.getTime();
  }).slice(0, 5);

  // Events
  const upcomingEvents = events.edges.filter(({ node }) => {
    const eventStartDate = +(new Date(node.startDate));
    return eventStartDate > yesterday.getTime();
  }).slice(0, 3);

  const filteredEvents = events.edges.filter(event => 
    event.node.speakerName && event.node.speakerName.toLowerCase().includes(page.name.toLowerCase())
  );

  let isHartree = false;
  if (roles.some(role => role.name ===  "Hartree Postdoctoral Fellow")) {
    isHartree = true;
  }

  return (
    <>
      <HTMLHead
        title={page.name}
        description={page.bio?.summary}
        path={page.fields.pagePath}
        openGraph={{
          type: "profile",
          profile: {
            firstName: page.firstName,
            lastName: page.lastName,
          },
          image: {
            url: imageUrl || "",
          },
        }}
      />
      <PageContentLayout>
        <PageContentMain>
          <PostSidebarHeader uri="/people" title="People"/>
          <PersonHero {...personHeroProps} />
          <PageContentBody>
            {
              !!(externalGroups?.length || page.bio?.processed || researchAreas?.length || sortedPublications.length || field_visit_date.length ) && (
                <>
                  <PageSection className="person-body">
                    {
                      !!page.bio?.processed &&
                      <>
                        <h2 className="publication-section-subhead mb-4">Bio</h2>
                        <TextHTMLParagraph text={page.bio} />
                      </>
                    }
                    {
                      !!researchAreas?.length &&
                      <>
                        <h2 className="publication-section-subhead mt-6 mb-3">Research Areas</h2>
                        <p>{researchAreas.map(({ name }) => name).join(", ")}</p>
                      </>
                    }
                    {
                      !!articles?.length && (
                        <PageSection title="Recent News" className="person-news-section">
                          <ArticleFeed limit={3} showMore={true}>
                            {
                              sortedArticles.map((article, i) => (
                                <ArticleFeedItem key={`article-${i}`} {...article} />
                              ))
                            }
                          </ArticleFeed>
                        </PageSection>
                      )
                    }
                    {
                      !!sortedPublications.length && (
                        <PageSection className="person-body">
                          <h2 className="publication-section-subhead mb-4">Recent Publications</h2>
                          <PublicationFeed limit={3} showMore={true}>
                            {
                              sortedPublications.map((publication, i) => (
                                <PublicationFeedItem
                                  key={`publication-${i}`} {...publication} />
                              ))
                            }
                          </PublicationFeed>
                        </PageSection>
                      )
                    }
                    {
                      !!courses && (
                        <PageSection className="page-body">
                          <h2 className="publication-section-subhead mb-4">Courses</h2>
                          <ul>
                            {
                              courses.map((course, i) => (
                                <li key={`course-${i}`}><a href={`${course.fields.pagePath}`}>{course.title}</a></li>
                              ))
                            }
                          </ul>
                        </PageSection>
                      )
                    }
                    { !!field_visit_date && field_visit_date.filter(date => date.field_visit_start_date !== null).length > 0 &&
                      <div>
                        <h2 className="publication-section-subhead mb-4">Visit Dates</h2>
                          <div className="text-content">
                          {
                            field_visit_date.map((date, i) => (
                              <div key={i}>
                                <div className="visitor-card__visit-dates">
                                  {date.startDate}{ date.endDate ?  ` - ${date.endDate}` : ""}
                                </div>
                              </div>
                            ))
                          }
                          </div>
                      </div>
                    }
                    
                    {!!filteredEvents.length && (
                      <PageSection className="person-body">
                        <h2 className="publication-section-subhead mb-4">Related Events</h2>
                          <EventFeed>
                          {filteredEvents.map(event => (
                              <EventFeedItem
                                key={event.node.id}
                                id={event.node.id}
                                date={event.node.date}
                                title={event.node.title}
                                fields={event.node.fields}
                                startDate={event.node.startDate}
                                speakerName={event.node.speakerName}
                                speakerInstitution={event.node.speakerInstitution}
                                relationships={event.node.relationships}
                                hideImage={false}
                              />
                            ))
                          }
                          </EventFeed>
                          <FancyLink href="/events" className="jqi-home-news-events-section__view-all">
                            <>View All QuICS Events</>
                          </FancyLink>
                      </PageSection>
                    )}
                    {
                      !!externalGroups?.length &&
                      <>
                        {
                          !!researchGroups?.length && !isHartree &&
                          <>
                            <h2 className="publication-section-subhead ">Research Group{researchGroups.length > 1 ? "s" : ""}</h2>
                            <GroupFeed>
                              {
                                researchGroups.map((group: JQI.GroupFeedItemProps, i: number) => (
                                  <GroupFeedItem key={`group-${i}`} {...group} />
                                ))
                              }
                            </GroupFeed>
                          </>
                        }
                        {
                          !!centers?.length &&
                          <>
                            <h2 className="publication-section-subhead ">Affiliated Research Centers</h2>
                            <GroupFeed>
                              {
                                centers.map((group: JQI.GroupFeedItemProps, i: number) => (
                                  <GroupFeedItem key={`group-${i}`} {...group} />
                                ))
                              }
                            </GroupFeed>
                          </>
                        }                  
                      </>
                    }

                  </PageSection>
                </>
              )
            }
          </PageContentBody>
        </PageContentMain>
      </PageContentLayout>
      
      {(!page.bio?.processed || page.bio.processed.trim() === '') && roles?.some(role => role.name.toLowerCase() === 'visitor') && (
        <>
          <div className="people-visits-section jqi-home-news-events-section">
            <PageContentLayout>
              <PageContentMain>
                <PageContentBody>
                  <section className="" aria-label="Visits and Events">
                      <PeopleVisits edges={filteredVisitData} />
                  </section>
                </PageContentBody>
              </PageContentMain>
            </PageContentLayout>
          </div>
        </>
      )}
    </>
  );
};

export default Person;

export const query = graphql`
  query PersonPage($id: String!, $firstName: String, $lastName: String, $fullName: String) {
    page: nodePerson(id: { eq: $id }) {
      meta: field_meta {
        description
      }
      id
      name: title
      created
      changed
      firstName: field_person_first_name
      lastName: field_person_last_name
      personTitle: field_person_title
      fields {
        pagePath
      }
      researchProfile: field_person_research_profile {
        uri
        title
      }
      bio: body {
        processed
        summary
      }
      relationships {
        image: field_person_image {
          mediaImage: field_media_image {
            alt
          }
          relationships {
            mediaImage: field_media_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1
                    transformOptions: {cropFocus: CENTER}
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
        }
        contactInfo: field_person_contact_info {
          email: field_person_ci_email
          officeAddress: field_person_ci_office_address {
            processed
          }
          officePhone: field_person_ci_office_phone
          lab: field_person_ci_lab
          relationships {
            organization: field_person_ci_organization {
              name
            }
          }
        }
        cv: field_person_cv {
          relationships {
            mediaDocument: field_media_document {
              uri {
                url
              }
            }
          }
        }
        researchAreas: field_research_areas {
          name
        }
        groups: field_sites {
          ...GroupFeedItem
        }
        roles: field_person_roles {
          name
        }
        articles: node__article {
          ...ArticleFeedItem
        }
        field_visit_date {
          startDate: field_visit_start_date(formatString: "MMM DD, YYYY")
          endDate: field_visit_end_date(formatString: "MMM DD, YYYY")
          field_visit_start_date
          field_visit_end_date
        }
        affiliation: field_visitor_affiliation {
          id
          name
        }
        courses: node__course {
          title
          fields {
            pagePath
          }
        }
      }
    }
    publications: allPublication(
      filter: {relationships: {author: {elemMatch: {first_name: {eq: $firstName}, last_name: {eq: $lastName}}}}, status: {eq: true}}
    ) {
      nodes {
        ...PublicationFeedItem
      }
    }
    otherVisits: allNodePerson(
      filter: {
        fields: {pagePath: {ne: null}}
        relationships: {
          field_person_roles: {elemMatch: {drupal_internal__tid: {eq: 20}}}, 
          field_visit_date: {elemMatch: {field_visit_start_date: {ne: "null"}}}
        }
      }
    ){
      edges {
        node {
          id
          name: title
          lastName: field_person_last_name
          personTitle: field_person_title
          fields {
            pagePath
          }
          relationships {
            field_visit_date {
              startDate: field_visit_start_date(formatString: "MMM DD, YYYY")
              endDate: field_visit_end_date(formatString: "MMM DD, YYYY")
              field_visit_start_date
              field_visit_end_date
            }
            affiliation: field_visitor_affiliation {
              name
            }
          }
        }
      }
    }
    events: allNodeEvent(
      filter: {field_event_speaker_name: {eq: $fullName}, fields: {pagePath: {ne: null}}, field_sites: {elemMatch: {drupal_internal__target_id: {eq: 1246}}}}
      sort: {field_event_start_datetime: DESC}
    ) {
        edges {
          node {
            id
            title
            fields {
              pagePath
            }
            startDate: field_event_start_datetime(formatString: "MMMM D, YYYY h:mm a")
            speakerName: field_event_speaker_name
            speakerInstitution: field_event_speaker_institution
            relationships {
              eventType: field_event_type {
                name
              }
              heroImage: field_event_hero {
                relationships {
                  hero: field_hero_image {
                    mediaImage: field_media_image {
                      alt
                    }
                    relationships {
                      mediaImage: field_media_image {
                        localFile {
                          childImageSharp {
                            gatsbyImageData(
                              layout: CONSTRAINED,
                              width: 230
                            )
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }    
    }
`;
