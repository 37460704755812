import React from "react";
import { PageProps, graphql } from "gatsby";
// import { useNavHierarchies } from "gatsby-jqi-graphql-helpers";
import { useSiteMetadata } from "gatsby-theme-jqi";
import { getImage, ImageDataLike } from "gatsby-plugin-image";
import {PageContentLayout, PodcastFeatureParagraph, TextHTMLParagraph, ArticleExperts, ArticleRelatedResearch, HeroParagraph, HTMLHead, PageContentBody, PageContentMain} from "gatsby-theme-jqi";
import PageContentHeader from "../components/PageContentHeader";
import PostSidebarHeader from "../components/PostSidebarHeader";
import { 
  getExternalGroups,
  getPublishedGroups,
  filterUnrelatedChildren,
  filterRelatedArticles,
  filterRelatedPublications,
  filterPeople
} from "gatsby-theme-jqi";

const Article: React.FC<PageProps<JQI.ArticleData, JQI.ArticleContext>> = ({
  data,
  pageContext,
}) => {
  const siteMetadata = useSiteMetadata();
  const { page } = data;
  const { defaultNodesByPath = {}, groupId } = pageContext;
  defaultNodesByPath[page.fields.pagePath] = { title: page.title };
  // const { breadcrumb } = useNavHierarchies(page.fields.pagePath, defaultNodesByPath)
  const { podcastFeature, researchAreas, people, groups, hero, relatedArticles } = page.relationships;

  let externalGroups = groups ? getExternalGroups(groups, groupId) : [];
  externalGroups = filterUnrelatedChildren(externalGroups, groupId);
  externalGroups = getPublishedGroups(externalGroups);
  const localRelatedArticles = relatedArticles ? filterRelatedArticles(relatedArticles, groupId) : [];
  const localRelatedPublications = page.publications ? filterRelatedPublications(page.publications, groupId) : [];
  const localPeople = people ? filterPeople(people, groupId) : [];

  // Extract imageUrl for use in og:image
  const metaImageData = hero?.relationships.heroImage?.relationships.mediaImage.localFile as ImageDataLike;
  const image = !!metaImageData ? getImage(metaImageData): null;
  const imageUrl = siteMetadata.siteUrl + image?.images.fallback?.src;

  // Extract description for use in meta
  const meta = page.metatag?.filter((tag) => tag.attributes.name === "description").map((tag) => (
    {
      description: tag.attributes.content,
    }
  )).pop();

  return (
    <>
      <HTMLHead
        title={page.title}
        description={page.body?.summary || meta?.description}
        path={page.fields.pagePath}
        openGraph={{
          article: {
            publishedTime: page.created,
            modifiedTime: page.changed,
          },
          image: {
            url: imageUrl || "",
          },
        }}
      />
      <PageContentLayout>
        <PageContentMain>
          <PostSidebarHeader uri="/about/news" title="News"/>
          <PageContentHeader title={page.title} publishDate={page.date} className="article-header">
          {
            !!hero &&
            <div className="page-content__header_bottom"><HeroParagraph {...hero} /></div>
          }
          </PageContentHeader>
          <PageContentBody>
            {
              !!podcastFeature &&
              <PodcastFeatureParagraph {...{
                ...podcastFeature,
                episodeTitle: page.title,
              }} />
            }
            {
              !!page.body?.processed &&
              <TextHTMLParagraph className="page-body" text={page.body} />
            }
            {
              (!!researchAreas?.length || !!localRelatedPublications?.length || !!localRelatedArticles?.length) &&
              <ArticleRelatedResearch
                researchAreas={researchAreas.map(a => a.name)}
                publications={localRelatedPublications}
                relatedArticles={localRelatedArticles}
              />
            }
            {
              (!!localPeople?.length || !!externalGroups?.length) &&
              <ArticleExperts people={localPeople} groups={externalGroups} />
            }
          </PageContentBody>
        </PageContentMain>
      </PageContentLayout>
    </>
  );
};

export default Article;

export const query = graphql`
  query ArticlePage($id: String!) {
    page: nodeArticle(id: { eq: $id }) {
      ...Article
      meta: field_meta {
        description
      }
    }
  }
`;
