import React from "react";

const ContactInfo: React.FC<JQI.PersonHeroContactInfoProps> = ({
  organization,
  email,
  office,
  lab,
}) => {
  return (
    <div className="person-hero__info">
      <dl className="person-hero__info-list">
        {
          !!email &&
          <>
            <dt className="person-hero__info-label"></dt>
            <dd className="person-hero__info-value">
              <a href={`mailto:${email}`}>{email}</a>
            </dd>
          </>
        }
          
        {
          !!office?.address &&
          <>
            <dt className="person-hero__info-label">Office:</dt>
            <dd className="person-hero__info-value" dangerouslySetInnerHTML={{ __html: office.address }} />
          </>
        }
        {
          !!office?.phone &&
          <>
            <dt className="person-hero__info-label">Office Phone:</dt>
            <dd className="person-hero__info-value">{office.phone}</dd>
          </>
        }
        {
          !!lab &&
          <>
            <dt className="person-hero__info-label">Lab:</dt>
            <dd className="person-hero__info-value">{lab}</dd>
          </>
        }
      </dl>
    </div>
  );
};

export default ContactInfo;