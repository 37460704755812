import React from "react";
import { ArticleFeed, FancyLink } from "gatsby-theme-jqi";
import { VisitFeedItem, EventFeed, EventFeedItem } from "gatsby-theme-quics";
import "./HomeVisits.scss";

export interface PeopleVisitsProps {
  edges: {
    node: JQI.PersonFeedItemProps;
  }[];
};

const PeopleVisits: React.FC<PeopleVisitsProps> = ({
  edges,
}) => (
  <div className="jqi-home-visits">
    <h2 className="jqi-home-visits__heading jqi-home-section__heading">Current & Upcoming Visitors</h2>
    <ArticleFeed className="jqi-home-news__feed people-visits-section">
      {
        edges.map((edge, i) => (
          <VisitFeedItem key={`jqi-home-news-feed-item-${i}`} {...edge.node} />
        ))
      }
    </ArticleFeed>
    <FancyLink href="/people/visitors" className="jqi-home-news-events-section__view-all">
      <>View All<span className="sr-only"> Visitors</span></>
    </FancyLink>
  </div>
);

export default PeopleVisits;
