import React from "react";
import classNames from "classnames";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {FancyLink} from "gatsby-theme-jqi";
import QuoteIcon from "../../icons/quote.svg";
import "./Blockquote.scss";

const Blockquote: React.FC<JQI.BlockquoteProps> = ({
  quote,
  author,
}) => {
  const imageProps = {
    data: !!author?.image?.data ? getImage(author.image.data) : null,
    src: author?.image?.src,
    alt: `Portrait of ${author?.name}`,
  };
  const classList = classNames({
    "blockquote1": true,
    "blockquote--has-image": !!imageProps.data || !!imageProps.src,
  });
  
  return (
    <blockquote className={classList}>
      <div className="blockquote_image">
        {
            !!imageProps.data &&
            <GatsbyImage  image={imageProps.data} alt={imageProps.alt} />
          }
          {
            !!imageProps.src &&
            <div className="blockquote__author-image">
              <img src={imageProps.src} alt={imageProps.alt} loading="lazy" />
            </div>
          }
          <div className="blockquote__icon" aria-hidden="true">
            <QuoteIcon height="37" width="42" />
          </div>
      </div>
      <div className="blockquote__content">
        <div
          className="blockquote__quote"
          dangerouslySetInnerHTML={{ __html: quote }}
        />
        <footer className="blockquote__footer">        
          {
            !!author?.name &&
            <div className="blockquote__author-info">
              <div className="blockquote_author">
              <div className="blockquote__author-name">{author?.name}</div>
              {/* {
                !!author?.contact &&
                <div className="blockquote__author-contact">{author?.contact?.map((item: any) => item.relationships.organization?.name).join(",")}</div>
              } */}
              {
              author?.titles &&
              <div className="blockquote__author-contact">
                {author?.titles}
              </div>
            }
              </div>
              <FancyLink href={author.url}>
                View Profile
              </FancyLink>              
            </div>
          }
        </footer>
      </div>      
    </blockquote>
  );
};

export default Blockquote;