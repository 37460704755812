import React from "react";
import { PageProps, graphql } from "gatsby";
import _ from "lodash";
import {GroupFeed, GroupFeedItem, HTMLHead, PageContentBody, PageContentMain, PageSection, PersonFeed, PersonFeedItem, PublicationDetails, PageContentLayout, HeroParagraph} from "gatsby-theme-jqi";
import { getExternalGroups, getPublishedGroups, filterUnrelatedChildren, filterPeople } from "gatsby-theme-jqi";
import { useSiteMetadata } from "gatsby-theme-jqi";
import { getImage, ImageDataLike } from "gatsby-plugin-image";
import PageContentHeader from "../components/PageContentHeader";
import PostSidebarHeader from "../components/PostSidebarHeader";

const Publication: React.FC<PageProps<JQI.PublicationData, JQI.PublicationContext>> = ({
  data,
  pageContext,
}) => {
  const { page } = data;
  const { defaultNodesByPath = {}, groupId } = pageContext;
  defaultNodesByPath[page.fields.pagePath] = { title: page.title };
  const { hero, printDocument, people, groups } = page.relationships;

  let externalGroups = groups ? getExternalGroups(groups, groupId) : [];
  externalGroups = filterUnrelatedChildren(externalGroups, groupId);
  externalGroups = getPublishedGroups(externalGroups);
  const localPeople = people ? filterPeople(people, groupId) : [];

  const siteMetadata = useSiteMetadata();
  // Extract imageUrl for use in og:image
  const metaImageData = hero?.relationships.heroImage?.relationships.mediaImage.localFile as ImageDataLike;
  const image = !!metaImageData ? getImage(metaImageData): null;
  const imageUrl = siteMetadata.siteUrl + image?.images.fallback?.src;

  // Extract description for use in meta
  const meta = page.metatag?.filter((tag) => tag.attributes.name === "description").map((tag) => (
    {
      description: tag.attributes.content,
    }
  )).pop();

  return (
    <>
      <HTMLHead
        title={page.title}
        description={page.abstract?.value || meta?.description}
        path={page.fields.pagePath}
        openGraph={{
          article: {
            publishedTime: page.created,
            modifiedTime: page.changed,
          },
          image: {
            url: imageUrl || "",
          },
        }}
      />
      <PageContentLayout>
        <PageContentMain>
          <PostSidebarHeader uri="/publications" title="Publications"/>
          <PageContentHeader title={page.title} className="big-header">
            {
              !!hero &&
              <HeroParagraph {...hero} />
            }
            {
              !!page.abstract &&
              <>
                <h2 className="page-content-subhead"><span>Abstract</span></h2>
                <div className="publications-abstract" dangerouslySetInnerHTML={{__html: page.abstract.processed}}></div>
              </>
            }
          </PageContentHeader>
          <PageContentBody>            
            <PageSection className="my-0 py-0">              
              <h2 className="publication-section-subhead">Publication Details</h2>
              <PublicationDetails {...page} />
              {
                !!printDocument?.length &&
                <>
                  <h2 className="publication-section-subhead">Download the Publication</h2>
                  <div className="publication-document">
                  {
                    printDocument.map((document, i) => (
                      <a className="link" key={`document-${i}`} href={process.env.GATSBY_DRUPAL_URL + document.relationships.mediaDocument.uri.url} download={true}>{document.name}</a>
                    ))
                  }
                  </div>
                </>
              }
            </PageSection>
            {/* {
              (!!localPeople?.length || !!externalGroups?.length) &&
              <PageSection title="Contributors" className="my-0 publications-section">
                {
                  !!localPeople?.length &&
                  <>
                    <h3 className="page-section__subhead">People</h3>
                    <PersonFeed>
                      {
                        localPeople.map((person: JQI.PersonFeedItemProps, i: number) => (
                          <PersonFeedItem key={`person-${i}`} {...person} />
                        ))
                      }
                    </PersonFeed>
                  </>
                }
                {
                  !!externalGroups?.length &&
                  <>
                    <h3 className="page-section__subhead">Groups</h3>
                    <GroupFeed>
                      {
                        externalGroups.map((group: JQI.GroupFeedItemProps, i: number) => (
                          <GroupFeedItem key={`group-${i}`} {...group} />
                        ))
                      }
                    </GroupFeed>
                  </>
                }
              </PageSection>
            } */}
          </PageContentBody>
        </PageContentMain>
      </PageContentLayout>
    </>
  );
};

export default Publication;

export const query = graphql`
  query PublicationQuery($id: String!) {
    page: publication(id: { eq: $id }) {
      ...Publication
      meta: field_meta {
        description
      }
    }
  }
`;
