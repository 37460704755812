import React from "react";
import { kebabCase } from "lodash";
import {SearchApp} from "gatsby-theme-jqi";
// import {
//   useNewsCategories,
//   useResearchAreas,
//   useSites,
// } from "gatsby-jqi-graphql-helpers";

const isBrowser = typeof window !== "undefined";

const createDictionary = (arr: JQI.TaxonomyTerm[]): { [key: string]: string } => {
  return arr.reduce((acc, cur: JQI.TaxonomyTerm) => ({
    ...acc,
    [kebabCase(cur.name)]: cur.id,
  }), {});
};

const ArticlesSearch: React.FC<JQI.ArticlesSearchProps> = ({
  args = [],
  hideSearch,
  groupId,
  queryArgs,
}) => {
  // const researchAreaOptions = useResearchAreas() as JQI.TaxonomyTerm[];
  // const categoryOptions = useNewsCategories() as JQI.TaxonomyTerm[];
  // const siteOptions = useSites() as JQI.TaxonomyTerm[];
  // const researchAreaFilter = {
  //   label: "Research Areas",
  //   property: "filters.researchAreas",
  //   options: researchAreaOptions,
  //   value: "",
  // } as JQI.SearchAppFilterProps;
  // const categoryFilter = {
  //   label: "News Categories",
  //   property: "filters.newsCategories",
  //   options: categoryOptions,
  //   value: "",
  // } as JQI.SearchAppFilterProps;
  // const siteFilter = {
  //   label: "Research Groups",
  //   property: "filters.sites",
  //   options: siteOptions,
  //   value: "",
  // } as JQI.SearchAppFilterProps;
  // If filters were passed via the Drupal View's contextual filters,
  // set the initial filter values accordingly. Otherwise, parse filters
  // from URL hash if any are present.
  // if (!!args.length) {
  //   const researchAreaFilterIds = researchAreaOptions.map(({ id }) => `${id}`);
  //   const categoryFilterIds = categoryOptions.map(({ id }) => `${id}`);
  //   const siteFilterIds = siteOptions.map(({ id }) => `${id}`);
  //   for (let i = 0; i < args.length; i++) {
  //     const arg = args[i];
  //     if (researchAreaFilterIds.indexOf(arg) !== -1) {
  //       researchAreaFilter.value = arg;
  //     }
  //     if (categoryFilterIds.indexOf(arg) !== -1) {
  //       categoryFilter.value = arg;
  //     }
  //     if (siteFilterIds.indexOf(arg) !== -1) {
  //       siteFilter.value = arg;
  //     }
  //   }
  // } else {
  //   const locationHash = isBrowser ? kebabCase(window.location.hash.slice(1)) : "";
  //   const researchAreaIdsBySlug = createDictionary(researchAreaOptions);
  //   const categoryIdsBySlug = createDictionary(categoryOptions);
  //   const siteIdsBySlug = createDictionary(siteOptions);
  //   researchAreaFilter.value = researchAreaIdsBySlug[locationHash] || "";
  //   categoryFilter.value = categoryIdsBySlug[locationHash] || "";
  //   siteFilter.value = siteIdsBySlug[locationHash] || "";
  // }
  return (
    <SearchApp
      indexName={`articles_index_${groupId}`}
      feedType="article"
      filters={[]}
      queryArgs={queryArgs}
      query = ""
      hideSearch={hideSearch || !!args.length}
    />
  );
};

export default ArticlesSearch;