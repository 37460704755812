import React from "react";
import {GroupFeed, GroupFeedItem, PersonFeed, PersonFeedItem} from "gatsby-theme-jqi";
import "./ArticleExperts.scss";

const ArticleExperts: React.FC<JQI.ArticleExpertsProps> = ({
  people,
  groups,
}) => {
  return (
    <section className="article-experts" aria-labelledby="article-experts-title">
      <h2 className="article-experts__title" id="article-experts-title">Experts</h2>
      {
        !!people.length &&
        <div className="article-experts__people">
          <h3 className="article-experts__subhead">People</h3>
          <PersonFeed>
            {
              people.map((person, i) => (
                <PersonFeedItem key={`person-${i}`} {...person} />
              ))
            }
          </PersonFeed>
        </div>
      }
      {
        !!groups.length &&
        <div className="article-experts__groups">
          <h3 className="article-experts__subhead">Groups</h3>
          <GroupFeed>
            {
              groups.map((group, i) => (
                <GroupFeedItem key={`group-${i}`} {...group} />
              ))
            }
          </GroupFeed>
        </div>
      }
    </section>
  );
}

export default ArticleExperts;
