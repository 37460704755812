import React from "react";
import { Link } from "gatsby";
import {FacebookIcon, YoutubeIcon} from "gatsby-theme-jqi";
import TwitterIcon from "../../icons/twitter.svg";
import "./SiteFooter.scss";

const defaultProps = {
  logos: [
    {
      href: "https://jqi.umd.edu/",
      src: "/jqi-logo-white.svg",
      alt: "UMD Joint Quantum Institute logo",
      width: 343,
      height: 69,
    },
    {
      href: "https://www.umd.edu/",
      src: "/umd-logo-white.svg",
      alt: "University of Maryland logo",
      width: 353,
      height: 98.7,
    },
    {
      href: "https://www.nist.gov/",
      src: "/nist-logo-white.svg",
      alt: "National Institute of Standards and Technology logo",
      width: 252,
      height: 33,
    },
  ],
  contact: {
    address: {
      full_address: "",
      street: {
        lineOne: "3100 Atlantic Building",
        lineTwo: "University of Maryland",
      },
      city: "College Park",
      state: "MD",
      zip: "20742",
    },
    phone: "(301) 314-1840",
    fax: "",
    media: "media",
    email: "jqi-info@umd.edu",
  },
  social: {
    twitter: "http://www.twitter.com/JointQuICS",
    youtube: "http://www.youtube.com/channel/UCWR9jfDYZXNBP8kirwFaqQw",
  },
  legalMenuItems: [
    {
      url: "https://www.umd.edu/privacy-notice",
      title: "Privacy Policy",
    },
    {
      url: "https://www.umd.edu/web-accessibility",
      title: "Web Accessibility",
    },
  ],
  orgName: "Joint Quantum Institute",
} as JQI.SiteFooterProps;

const SiteFooter: React.FC<JQI.SiteFooterProps> = ({
  logos = defaultProps.logos,
  contact = defaultProps.contact,
  social = defaultProps.social,
  legalMenuItems = defaultProps.legalMenuItems,
  orgName = defaultProps.orgName,
  menuItems,
}) => {
  const { address, phone, fax, media, email, description } = contact;
  const { facebook, twitter, youtube } = social;
  const phoneDigits = !!phone ? phone.replace(/[^0-9]/g, "") : "" as string;
  const formattedPhone = !!phoneDigits ? `(${phoneDigits.substring(0, 3)}) ${phoneDigits.substring(3, 6)}-${phoneDigits.substring(6)}` : "" as string;
  const faxDigits = !!fax ? fax.replace(/[^0-9]/g, "") : "" as string;
  const formattedFax = !!faxDigits ? `(${faxDigits.substring(0, 3)}) ${faxDigits.substring(3, 6)}-${faxDigits.substring(6)}` : "" as string;
  return (
    <footer className="site-footer">
      <div className="site-footer__main">
        {
          !!logos.length &&
          <div className="site-footer__logos hidden 1000:block">
            <ul className="site-footer__logos-list">
              {
                logos.map((logo, i) => (
                  <li className="site-footer__logos-item" key={`site-footer-logo-${i}`}>
                    <a
                      className="responsive-img"
                      style={{paddingTop: `${(logo.height / logo.width) * 100}%`}}
                      href={logo.href}
                      target="_blank"
                    >
                      <img src={logo.src} alt={logo.alt} loading="lazy" />
                    </a>
                  </li>
                ))
              }
            </ul>
          </div>
        }
        <nav className="site-footer__nav" aria-label="Footer">
          <ul>
            {
              menuItems.map((item, i) => (
                <li key={`site-footer-menu-item-${i}`}>
                  <Link to={item.url}>{item.title}</Link>
                </li>
              ))
            }
          </ul>
          {
          !!logos.length &&
          <div className="site-footer__logos mobile_footer__part">
            <ul className="site-footer__logos-list">
              {
                logos.map((logo, i) => (
                  <li className="site-footer__logos-item" key={`site-footer-logo-${i}`}>
                    <a
                      className="responsive-img"
                      style={{paddingTop: `${(logo.height / logo.width) * 100}%`}}
                      href={logo.href}
                      target="_blank"
                    >
                      <img src={logo.src} alt={logo.alt} loading="lazy" />
                    </a>
                  </li>
                ))
              }
            </ul>
          </div>
        }
          <div className="site-footer__contact">
          <h2 className="site-footer__contact-title">Contact Us</h2>
          {
            !!address && (
              <>
                <div className="site-footer__contact-address">
                  <p>
                    { address.full_address ? (<>{address.full_address}</>) : 
                      (<>
                        {address.street.lineOne}
                        <br />
                        {
                          !!address.street.lineTwo && (
                            <>
                              {address.street.lineTwo}
                              <br />
                            </>
                          )
                        }
                        {address.city}, {address.state} {address.zip}
                        </>
                      )
                    }
                  </p>
                </div>
              </>
            )
          }
          <div className="site-footer__contact-phone-email">
            <p>
              {
                !!phoneDigits && (
                  <>
                    <span>Phone: </span>
                    <a href={`tel:+1${phoneDigits}`}>{formattedPhone}</a>
                    <br />
                  </>
                )
              }
              {
                !!faxDigits && (
                  <>
                    <span>Fax: </span>
                    <a href={`tel:+1${faxDigits}`}>{formattedFax}</a>
                    <br />
                  </>
                )
              }
              <span>Email: </span>
              <a href={`mailto:${email}`}>{email}</a><br/>
              {
                !!media && (
                  <>
                    <span>Media inquiries: </span>
                    <a href={`mailto:${media}`}>{media}</a>
                    <br />
                  </>
                )
              }
            </p>
          </div>
        </div>
        { !!description && (
          <div className="site-footer__contact-description  999:hidden">{description}</div>
        )
        }
        </nav>        
        {
          (!!facebook || !!twitter || !!youtube) &&
          <nav className="site-footer__social" aria-label="Social">
            <ul>
              {
                !!twitter &&
                <li>
                  <a href={twitter}>
                    <span className="sr-only">Twitter</span>
                    <TwitterIcon className="icon icon-twitter" aria-hidden="true" width="20" height="20" />
                  </a>
                </li>
              }
              {
                !!facebook &&
                <li>
                  <a href={facebook}>
                    <span className="sr-only">Facebook</span>
                    <FacebookIcon className="icon icon-facebook" aria-hidden="true" width="11" height="22" />
                  </a>
                </li>
              }
              {
                !!youtube &&
                <li>
                  <a href={youtube}>
                    <span className="sr-only">Youtube</span>
                    <YoutubeIcon className="icon icon-youtube" aria-hidden="true" width="22" height="15" />
                  </a>
                </li>
              }
            </ul>

            { !!description && (
              <div className="site-footer__contact-description mobile_footer__part ">{description}</div>
            )
            }
          </nav>
        }
        
      </div>
      <div className="site-footer__legal">
        <span className="site-footer__copyright">© {orgName}</span>
        <nav className="site-footer__legal-nav" aria-label="Legal">
          <ul>
            {
              legalMenuItems.map((item, i) => (
                <li key={`site-footer-legal-menu-item-${i}`}>
                  <a href={item.url}>{item.title}</a>
                </li>
              ))
            }
          </ul>
        </nav>
      </div>
    </footer>
  );
};

export default SiteFooter;
