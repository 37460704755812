import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactInfo from "./ContactInfo";
import AdditionalInfo from "./AdditionalInfo";
import PostSidebarHeader from "../PostSidebarHeader";
import "./PersonHero.scss";

const PersonHero: React.FC<JQI.PersonHeroProps> = ({
  name,
  personTitle,
  roles,
  image,
  contactInfo,
  cv,
  researchProfile,
  breadcrumb,
  affiliations,
}) => {
  const imageData = !!image.data ? getImage(image.data) : null;
  const isTitleInRoles = roles?.includes(personTitle);

  return (
    <div className="person-hero">      
      <div className="person-hero__inner">
        <h1 className="person-hero__name">{name}</h1>
        {personTitle && 
          <p className="person-hero__titles">{personTitle}</p>
        }
        {roles && !isTitleInRoles &&
          <div className="person-hero__roles w-full">
            {roles.map((role, i) => (
              <span key={i} className="person-type">
                {role}{i < roles.length - 1 ? ', ' : ''}
              </span>
            ))}
          </div>
        }
        {affiliations && affiliations.length > 0 &&
          <div className="person-hero__affiliation_title">
            <h2 className="person-hero__info-section-title">Visitor Affiliation</h2>
            <div className="person-hero__affiliations">{affiliations.join(', ')}</div>
          </div>
        }
        {
          (!!image && !!imageData) &&
          <GatsbyImage className="person-hero__image" image={imageData} alt={image.alt || `Portrait of ${name}`} />
        }
        <div className="person-hero__info-sections">
          {
            contactInfo.some(contact => contact.email !== null || contact.office.phone !== null || contact.lab !== null) &&
            <div className="person-hero__info-section">
              <h2 className="person-hero__info-section-title">Contact Information</h2>
              {
                contactInfo.map((info, i) => <ContactInfo key={`contact-info-${i}`} {...info} />)
              }
            </div>
          }
          {
            (!!cv || !!researchProfile?.length) &&
            <div className="person-hero__info-section">
              <h2 className="person-hero__info-section-title">Additional Information</h2>
              <AdditionalInfo cv={cv} researchProfile={researchProfile} />
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default PersonHero;
