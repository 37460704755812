import React from "react";
import { navigate } from "gatsby";
import classNames from "classnames";
import {useHover, BasicLink} from "gatsby-theme-jqi";
import "./VisitFeedItem.scss";

const VisitFeedItem: React.FC<JQI.PersonFeedItemProps> = ({
  name,
  fields,
  relationships
}) => {
  const [ hoverRef, hovered ] = useHover();
  const classList = classNames({
    "article-feed-item": true,
    hovered,
  });

  const startDate = relationships?.field_visit_date?.startDate;
  const endDate = relationships?.field_visit_date?.endDate;
  if (!startDate && !endDate) return null;
  
  let dateRange = startDate;
  if (endDate) {
    dateRange += ` to ${endDate}`;
  }
  return (
    <article className={classList} onClick={() => { navigate(fields.pagePath) }} ref={hoverRef}>
      <div className="article-feed-item__content">
        <h2 className="article-feed-item__title">
          {
            !!fields?.pagePath
              ? <BasicLink url={fields.pagePath}>{name}</BasicLink>
              : <span>{name}</span>
          }

          { relationships.affiliation && <><span className="line">|</span>{relationships.affiliation?.map((item) => item.name).join(", ")}</>}

          { relationships.field_visit_date && 
            <>
              {dateRange && (
                <>
                  <span className="line">|</span>
                  {dateRange}
                </>
              )}
            </>
            }

          
        </h2>
      </div>
    </article>
  );
};

export default VisitFeedItem;
