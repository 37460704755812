import React, { useEffect } from "react";
import { Globals } from "react-spring";
import { useReduceMotion, BaseLayout } from "gatsby-theme-jqi";
import useHeaderMenu from "../hooks/use-header-menu";
import useFooterMenu from "../hooks/use-footer-menu";
 
const Provider: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  children,
}) => {
  const headerProps = {
    logo: {
      src: "/quics-logo.svg",
      alt: "Joint Center for Quantum Information and Computer Science (QuICS)", // Update this to use the actual group name
      width: 301, // Set this to the actual width of the logo
      height: 70, // Set this to the actual height of the logo
    },
     menuItems: useHeaderMenu() } as JQI.SiteHeaderProps;
  const footerProps = { logos: [
    {
      href: "https://quics.umd.edu/",
      src: "/quics-logo.svg",
      alt: "The Joint Center for Quantum Information and Computer Science (QuICS) logo",
      width: 343,
      height: 69,
    },
    {
      href: "https://www.umd.edu/",
      src: "/umd-logo-white.svg",
      alt: "University of Maryland logo",
      width: 353,
      height: 98.7,
    },
    {
      href: "https://www.nist.gov",
      src: "/nist-logo-white.svg",
      alt: "National Institute of Standards and Technology logo",
      width: 252,
      height: 33,
    },
  ],
  contact: {
    address: {
      full_address: "QuICS 3100 Atlantic Building \nUniversity of Maryland College Park, \nMD 20742-2420",
    },
    phone: "301.314.1840",
    email: "quics-coordinator@umiacs.umd.edu",
    media: "tomvent@umiacs.umd.edu",
    description: "QuICS receives substantial administrative and technical support from the University of Maryland Institute for Advanced Computer Studies."
  },
  orgName: "The Joint Center for Quantum Information and Computer Science",
  menuItems: useFooterMenu() } as JQI.SiteFooterProps;
  // Disable React Spring animations based on user's OS-level reduced motion preference.
  const prefersReducedMotion = useReduceMotion() as boolean;
  useEffect(() => {
    Globals.assign({
      skipAnimation: prefersReducedMotion,
    })
  }, [prefersReducedMotion]);
  return (
    <BaseLayout headerProps={headerProps} footerProps={footerProps}>
      {children}
    </BaseLayout>
  );
};

export default Provider;