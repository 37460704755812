import { useStaticQuery, graphql } from "gatsby";
import { useSiteMetadata } from "packages/gatsby-theme-jqi";

const useHeaderMenu = (): JQI.MenuItem[] => {
	const { allMenuItems, site } = useStaticQuery(
		graphql`
			query HeaderMenu {
				site {
					siteMetadata {
						groupId
					}
				}
				allMenuItems(filter: { menu_name: { eq: "quics-header-menu" } }) {
					edges {
						node {
							id
							title
							url
							parent {
								id
							}
						}
					}
				}
			}
		`,
	);

	// Create a dictionary of all menu items by ID to use to create a tree of all menu items
	const menuItemsById = allMenuItems.edges.reduce(
		(acc: any, cur: any) => ({
			...acc,
			[cur.node.id]: {
				id: cur.node.id,
				url: cur.node.url,
				title: cur.node.title,
				parent: cur.node.parent?.id,
				children: [],
			},
		}),
		{},
	);

	const groupId = site.siteMetadata.groupId;

	// Create menu tree
	return allMenuItems.edges.reduce((acc: any, cur: any) => {
		const menuItem = menuItemsById[cur.node.id];

		menuItem.url = menuItem.url.replace(new RegExp(`/${groupId}`), "");

		if (!!menuItem.parent) {
			// If this isn't a top level item, find its parent and add it to the parent in the dictionary
			const parentItem = menuItemsById[menuItem.parent];
			parentItem.children.push(menuItem);
		} else {
			// If this is a top level menu, add the object from the dictionary to this array (now containing child items)
			acc.push(menuItem);
		}

		return acc;
	}, []);
};

export default useHeaderMenu;
