import React from "react";
import { kebabCase } from "lodash";
import {SearchApp} from "gatsby-theme-jqi";
import {
  useQuicsEventTypes,
  useResearchAreas,
  useSemesters,
} from "gatsby-jqi-graphql-helpers";

const isBrowser = typeof window !== "undefined";

const createDictionary = (arr: JQI.TaxonomyTerm[]): { [key: string]: string } => {
  return arr.reduce((acc, cur: JQI.TaxonomyTerm) => ({
    ...acc,
    [kebabCase(cur.name)]: cur.id,
  }), {});
};

const EventsSearch: React.FC<JQI.EventsSearchProps> = ({
  indexName,
  args = [],
  hideSearch,
  groupId,
  queryArgs,
  noResultsInfo
}) => {

  const quicsEventTypeOptions = useQuicsEventTypes() as JQI.TaxonomyTerm[];
  console.log(quicsEventTypeOptions);

  const researchAreaOptions = useResearchAreas() as JQI.TaxonomyTerm[];
  const semesterOptions = useSemesters() as JQI.TaxonomyTerm[];
  const eventTypeFilter = {
    label: "Event Types",
    property: "filters.quicsEventType",
    options: quicsEventTypeOptions,
    value: "",
  } as JQI.SearchAppFilterProps;
  const researchAreaFilter = {
    label: "Research Areas",
    property: "filters.researchAreas",
    options: researchAreaOptions,
    value: "",
  } as JQI.SearchAppFilterProps;
  const semesterFilter = {
    label: "Event Seasons",
    property: "filters.semester",
    options: semesterOptions,
    value: "",
  } as JQI.SearchAppFilterProps;
  // If filters were passed via the Drupal View's contextual filters,
  // set the initial filter values accordingly. Otherwise, parse filters
  // from URL hash if any are present.
  if (!!args.length) {
    const eventTypeFilterIds = quicsEventTypeOptions.map(({ id }) => `${id}`);
    const researchAreaFilterIds = researchAreaOptions.map(({ id }) => `${id}`);
    const semesterFilterIds = semesterOptions.map(({ id }) => `${id}`);
    for (let i = 0; i < args.length; i++) {
      const arg = args[i];
      if (eventTypeFilterIds.indexOf(arg) !== -1) {
        eventTypeFilter.value = arg;
      }
      if (researchAreaFilterIds.indexOf(arg) !== -1) {
        researchAreaFilter.value = arg;
      }
      if (semesterFilterIds.indexOf(arg) !== -1) {
        semesterFilter.value = arg;
      }
    }
  } else {
    const locationHash = isBrowser ? kebabCase(window.location.hash.slice(1)) : "";
    const eventTypeIdsBySlug = createDictionary(quicsEventTypeOptions);
    const researchAreaIdsBySlug = createDictionary(researchAreaOptions);
    const semesterIdsBySlug = createDictionary(semesterOptions);
    eventTypeFilter.value = eventTypeIdsBySlug[locationHash] || "";
    researchAreaFilter.value = researchAreaIdsBySlug[locationHash] || "";
    semesterFilter.value = semesterIdsBySlug[locationHash] || "";
  }
  const noFilterValues = !researchAreaFilter.value && !semesterFilter.value;

  return (
    <SearchApp
      indexName={`${indexName}${groupId}`}
      feedType="event"
      filters={[
        eventTypeFilter,
        // semesterFilter,
      ]}
      hideSearch={hideSearch || !!args.length}
      queryArgs={queryArgs}
      noResultsText={noFilterValues ? `${noResultsInfo}` : undefined}
    />
  );
};

export default EventsSearch;
