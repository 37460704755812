import React from "react";
import { Link, navigate } from "gatsby";
import { GatsbyImage, getImage, ImageDataLike, GatsbyImageProps } from "gatsby-plugin-image";
import classNames from "classnames";
import {useHover, BasicLink} from "gatsby-theme-jqi";
import "./EventFeedItem.scss";


const EventFeedItem: React.FC<JQI.EventFeedItemProps> = ({
  title,
  fields,
  startDate,
  speakerName,
  speakerInstitution,
  relationships,
  hideImage
}) => {
  const [ hoverRef, hovered ] = useHover();
  const imageData = relationships.heroImage?.relationships.hero?.relationships.mediaImage.localFile as ImageDataLike;
  const imageProps = {
    image: !!imageData ? getImage(imageData) : null,
    alt: relationships.heroImage?.relationships.hero?.mediaImage.alt || "",
  } as GatsbyImageProps;

  const classList = classNames({
    "event-feed-item": true,
    "event-feed-item-with-image": (!hideImage && !!imageProps.image),
    hovered,
  });

  return (
    <article className={classList} onClick={() => { navigate(fields.pagePath) }} ref={hoverRef}>
      {
        (!hideImage && !!imageProps.image) &&
        <GatsbyImage className="article-feed-item__image" {...imageProps} />
      }
      <div>
        <div className="event-feed-item__top">
          <div className="event-feed-item__date-time">{startDate}</div>
          <div className="event-feed-item__event-type">{relationships.eventType.name}</div>
        </div>
        <div className="event-feed-item__content">
          <h2 className="event-feed-item__title">
            {
              !!fields?.pagePath
                ? <BasicLink url={fields.pagePath}>{title}</BasicLink>
                : <span>{title}</span>
            }
          </h2>
          <p className="event-feed-item__speaker">
            <span className="event-feed-item__speaker-name">{speakerName}</span>
            {
              !!speakerInstitution &&
              <span className="event-feed-item__speaker-institution">{speakerInstitution}</span>
            }
          </p>
        </div>
        </div>
    </article>
  );
};

export default EventFeedItem;