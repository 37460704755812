import React from "react";
import SVGLinkExternalWhite from "../../icons/SVG-link-external-white.svg";

const AdditionalInfo: React.FC<JQI.PersonHeroAdditionalInfoProps> = ({
  cv,
  researchProfile,
}) => (
  <div className="person-hero__info">
    <dl className="person-hero__info-list">
      {
        !!cv &&
        <>
          <dt className="person-hero__info-label">CV:</dt>
          <dd className="person-hero__info-value">
            <a href={cv} download>
              <span className="person-hero__cv">{cv.split("/").pop()}</span>
              <SVGLinkExternalWhite className="link--external_white_svg" />
            </a>
          </dd>
        </>
      }
      {
        !!researchProfile &&
        <>
          {
            researchProfile.map((profile, i) => {
              if(profile.uri.includes("scholar.google")) {
                profile.title = "Google Scholar";
              }
              return(
                <div key={`research-profile${i}`}>
                  <dt className="person-hero__info-label sr-only">{profile.title ? profile.title : "Research Profile "}</dt>
                  <dd className="person-hero__info-value">
                    <a className="link--external link--external_white_svg" href={profile.uri} target="_blank">
                      <span className="sr-only">View Profile</span>
                      <span aria-hidden="true">{profile.title ? `${profile.title} ` : "Research Profile "}</span>
                      <span><SVGLinkExternalWhite className="link--external_white_svg" /></span>
                    </a>
                  </dd>
                </div>
              )
            })
          }
        </>
      }
    </dl>
  </div>
);

export default AdditionalInfo;